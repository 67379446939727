import DateRangePreset from '@/core/utils/DateRangePreset';

export function dispatchEventShortcut(type: string): void {
    const eventShortcut = new CustomEvent('event_shortcut', {bubbles: true, detail: {type: type}});
    window.dispatchEvent(eventShortcut);
}

export const TIMEZONE_OPTIONS = [
	{v: -8, t: '-08:00'},
	{v: -7, t: '-07:00'},
	{v: -6, t: '-06:00'},
	{v: -5, t: '-05:00'},
	{v: -4, t: '-04:00'},
	{v: -3, t: '-03:00'},
	{v: -2, t: '-02:00'},
	{v: -1, t: '-01:00'},
	{v: 0, t: '+00:00 UTC'},
	{v: 1, t: '+01:00'},
	{v: 2, t: '+02:00'},
	{v: 3, t: '+03:00'},
	{v: 4, t: '+04:00'},
	{v: 5, t: '+05:00'},
	{v: 6, t: '+06:00'},
	{v: 7, t: '+07:00'},
	{v: 8, t: '+08:00'},
];

export const SHORTCUTS = [
    // {
    //     text: 'This Hour',
    //     onClick() {
    //         dispatchEventShortcut('this_hour');
    //         return (new DateRangePreset()).thisHour();
    //     }
    // },
    // {
    //     text: 'Last Hour',
    //     onClick() {
    //         dispatchEventShortcut('last_hour');
    //         return (new DateRangePreset()).lastHour();
    //     }
    // },
    // {
    //     text: 'Today',
    //     onClick() {
    //         dispatchEventShortcut('today');
    //         return (new DateRangePreset()).today();
    //     }
    // },
    // {
    //     text: 'Yesterday',
    //     onClick() {
    //         dispatchEventShortcut('yesterday');
    //         return (new DateRangePreset()).yesterday();
    //     }
    // },
    // {
    //     text: 'Last 7 Days',
    //     onClick() {
    //         dispatchEventShortcut('last_7_days');
    //         return (new DateRangePreset()).last7days();
    //     }
    // },
    // {
    //     text: 'Last 30 Days',
    //     onClick() {
    //         dispatchEventShortcut('last_30_days');
    //         return (new DateRangePreset()).last30days();
    //     }
    // },
    // {
    //     text: 'Last Month',
    //     onClick() {
    //         dispatchEventShortcut('last_month');
    //         return (new DateRangePreset()).lastMonth();
    //     }
    // },
    // {
    //     text: 'This Month',
    //     onClick() {
    //         dispatchEventShortcut('this_month');
    //         return (new DateRangePreset()).thisMonth();
    //     }
    // },
]