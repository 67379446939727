import { ref, Ref, computed } from 'vue';
import moment from 'moment';
import { TimeRangeProps } from './interfaces/TimeRange';
import { DateRangeDates } from './interfaces/Datepicker';

export function useTimeRange(props: TimeRangeProps) {
    const [startDate, endDate] = props.dateRange;
    const {momentStartDate, momentEndDate} = {
        momentStartDate: moment(startDate),
        momentEndDate: moment(endDate)
    };

    let startDateHours: Ref<string> = ref(momentStartDate.format("HH"));
    let startDateMinutes: Ref<string> = ref(momentStartDate.format("mm"));
    let endDateHours: Ref<string> = ref(momentEndDate.format("HH"));
    let endDateMinutes: Ref<string> = ref(momentEndDate.format("mm"));

    const resultRange = computed((): DateRangeDates => {
        const start = momentStartDate;
        const end = momentEndDate;
        start.set({h: parseInt(startDateHours.value), m: parseInt(startDateMinutes.value)})
        end.set({h: parseInt(endDateHours.value), m: parseInt(endDateMinutes.value)})
        return [
           start.toDate(),
           end.toDate()
        ]
    });


    return {
        startDateHours,
        startDateMinutes,
        endDateHours,
        endDateMinutes,
        resultRange,
    }
}
