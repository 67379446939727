
import { defineComponent, ref, Ref } from 'vue';
import '@/assets/css/datepicker_tailwind.css';
import { useDatepicker } from '@/hooks/useDatepicker';
import TimeRangePicker from './TimeRangePicker.vue';
import DatePicker from 'vue-datepicker-next'; // https://github.com/mengxiong10/vue-datepicker-next
import 'vue-datepicker-next/index.css';
import TimezonePicker from './TimezonePicker.vue';
import { SHORTCUTS } from './constants';
import DateRangePreset from '@/core/utils/DateRangePreset';

function dispatchEventShortcut(type: string): void {
	const eventShortcut = new CustomEvent('event_shortcut', {bubbles: true, detail: {type: type}});
	window.dispatchEvent(eventShortcut);
}
export default defineComponent({
	components: { TimeRangePicker, DatePicker, TimezonePicker },
	props: {
		modelValue: {
			default: {
				timezone: 0,
				dateRange: null,
			},
			required: true,
		},
		format: {
			type: String,
			required: true,
		},
		block_minutes: {
			type: Boolean,
			default: false,
			required: false,
		},
		display: {
			type: String as () => 'default' | 'inline',
			default: () => 'default',
			required: false,
		},
	},
	data() {
		return {
			shortcuts: SHORTCUTS,
			opened: false,
			interval: null,
		};
	},
	methods: {
		setDate(v: [Date, Date]) {
			this.dateRangeRef = v;
			this.closePicker();
		},
		closePicker() {
			this.opened = false;
		},
		updateRanges(){
			const tzGap = (new DateRangePreset()).pickTZGap(this.modelValue?.timezone);
			let ranges = [
				{
					text: 'This Hour',
					onClick() {
						dispatchEventShortcut('this_hour');
						return (new DateRangePreset()).thisHour(tzGap);
					}
				},
				{
					text: 'Last Hour',
					onClick() {
						dispatchEventShortcut('last_hour');
						return (new DateRangePreset()).lastHour(tzGap);
					}
				},
				{
					text: 'Today',
					onClick() {
						dispatchEventShortcut('today');
						return (new DateRangePreset()).today(tzGap);
					}
				},
				{
					text: 'Yesterday',
					onClick() {
						dispatchEventShortcut('yesterday');
						return (new DateRangePreset()).yesterday(tzGap);
					}
				},
				{
					text: 'Last 7 Days',
					onClick() {
						dispatchEventShortcut('last_7_days');
						return (new DateRangePreset()).last7days(tzGap);
					}
				},
				{
					text: 'Last 30 Days',
					onClick() {
						dispatchEventShortcut('last_30_days');
						return (new DateRangePreset()).last30days(tzGap);
					}
				},
				{
					text: 'Last Month',
					onClick() {
						dispatchEventShortcut('last_month');
						return (new DateRangePreset()).lastMonth(tzGap);
					}
				},
				{
					text: 'This Month',
					onClick() {
						dispatchEventShortcut('this_month');
						return (new DateRangePreset()).thisMonth(tzGap);
					}
				},
			];
			this.shortcuts = ranges;
		}
	},
	setup(props, { emit }) {
		const data = {
			dateRange: props.modelValue.dateRange,
			timezone: props.modelValue.timezone,
			format: props.format,
		};
		const { dateRangeRef, timezoneRef } = useDatepicker(data);
		const shortcutRef: Ref<null | string> = ref(null);
		window.addEventListener('event_shortcut', (e: any) => {
			shortcutRef.value = e.detail.type;
			emit('shortcut-updated', e.detail.type);
		});
		return {
			dateRangeRef,
			timezoneRef,
			shortcutRef,
		};
	},
	watch: {
		dateRangeRef(): void {
			this.$emit('update:modelValue', {
				timezone: this.timezoneRef,
				dateRange: this.dateRangeRef,
			});
		},
		timezoneRef(): void {
			this.$emit('update:modelValue', {
				timezone: this.timezoneRef,
				dateRange: this.dateRangeRef,
			});
		},
		modelValue(v) {
			this.dateRangeRef = v.dateRange;
			this.timezoneRef = v.timezone;
		}
	},
	mounted(): void {
		this.$emit('update:modelValue', {
			timezone: this.timezoneRef,
			dateRange: this.dateRangeRef,
		});
		this.updateRanges();
		this.interval = setInterval(() => {
			this.updateRanges();
		}, 3 * 1000);
	},
	beforeUnmount() {
		clearInterval(this.interval);
	}
});
