
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Entity',
	props: {
		id: {
			default: '',
		},
		name: {
			default: ''
		},
		type: {
			default: ''
		},
		params: {
			default: null
		},
	},
	data() {
		return {
			busy: false,
			modals: {
				offer: false,
				placement: false
			},
			hoverTimeout: null
		}
	},
	methods: {
		emitClick() {
			const payload = {
				type: this.type,
				id: this.id,
				params: this.params
			};
			this.$emit('clickCallback', payload);
		},
		selectText(e) {
			const node = e.target;
			let selection = window.getSelection();
			let range = document.createRange();
			range.selectNodeContents(node);
			// @ts-ignore
			selection.removeAllRanges();
			// @ts-ignore
			selection.addRange(range);
		}
	},
});
