import { ref, Ref } from 'vue';
import moment from 'moment';
import {DatepickerProps, DatepickerData, DateRangeDates} from './interfaces/Datepicker';


export function useDatepicker(props: DatepickerProps) {
    const datepickerData: DatepickerData = !props.dateRange ? getLastHourDateRange(props.timezone, props.format) : props as DatepickerData;
    const timezoneRef: Ref<number> = ref(datepickerData.timezone);
    const dateRangeRef = ref([
        datepickerData.dateRange[0],
        datepickerData.dateRange[1]
    ]) as Ref<DateRangeDates>;

    return {
        dateRangeRef,
        timezoneRef
    }
}

function getLastHourDateRange(timezone: number, format: string): DatepickerData {
    let tz = timezone || 0;
    let offset = new Date().getTimezoneOffset() / 60;
    let gap = -offset - tz;
    const dateRange = [
        moment(moment().subtract(gap, 'hours').format('DD/MM/YYYY ') + '00:00:00', format).toDate(),
        moment(moment().subtract(gap, 'hours').format('DD/MM/YYYY ') + '23:59:59', format).toDate()
    ];
    return {
        timezone: tz,
        dateRange: [dateRange[0], dateRange[1]],
        format
    };
}