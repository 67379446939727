
import { defineComponent } from "vue";
import DataService from "@/core/services/DataService";

export default defineComponent({
  name: "OfferSelector",
  props: {
    modelValue: {
      default: [],
      required: true,
    },
    multiple: {
      default: true,
      required: false,
    },
    selectedOptions: { default: [], required: false },
  },
  computed: {
    mdl: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
  data() {
    return {
      options: [],
      showArchive: false,
      loading: false,
    };
  },
  methods: {
    async search(query) {
      if (query !== "") {
        this.loading = true;
        this.options = await DataService.entity.offer.getOptions(query);
        this.loading = false;
      }
    },
    async refreshModel() {
      let options = this.modelValue;
      let actualOptions = [];
      let idOptions: number[] = [];
      if (!this.multiple) {
        if (+options) {
          // @ts-ignore
          idOptions = [+options];
          // @ts-ignore
          actualOptions.push(+options);
        }
      } else {
        actualOptions = options;
        idOptions = options
          .filter((o) => typeof o !== "object")
          .map((id) => +id);
      }
      if (idOptions.length) {
        const selectedOptions = await DataService.entity.offer.getOptions(
          null,
          idOptions
        );
        let optionsMap = {};
        selectedOptions.forEach((o) => {
          optionsMap[o.v] = o;
        });
        let dropdownOptions: any[] = [];
        actualOptions.forEach((o, i) => {
          if (typeof o === "number") {
            if (optionsMap[o]) {
              dropdownOptions.push(optionsMap[o]);
            }
          } else {
            dropdownOptions.push(o);
          }
        });
        this.options = dropdownOptions;
      }
    },
  },
  watch: {
    mdl: {
      handler() {
        this.refreshModel();
      },
    },
  },
  async beforeMount() {
    this.refreshModel();
  },
});
