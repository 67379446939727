
import { defineComponent } from 'vue';

export default defineComponent({
    name: "TimePicker",
    props: {
        hours: {
            required: true,
            type: String,
            default: "00"
        },
        minutes: {
            required: true,
            type: String,
            default: "00"
        },
        block_minutes: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    computed: {
        getHourRange(): string[] {
            return new Array(24).fill(0).map((_, i) => {
                return `0${i}`.slice(-2);
            })
        },
        getMinuteRange(): string[] {
            return new Array(60).fill(0).map((_, i) => {
                return `0${i}`.slice(-2);
            })
        }
    },
});
