export default class DateRangePreset {
    
    public thisHour(tzGap): DateRange {
        return this.getHoursRange(tzGap);
    }

    public lastHour(tzGap): DateRange {
        return this.getHoursRange(tzGap, -1, -1);
    }

    public today(tzGap): DateRange {
        return this.getTodayRange(tzGap);
    }

    public yesterday(tzGap): DateRange {
        return this.getDaysRange(tzGap, -1, -1);
    }

    public last7days(tzGap): DateRange {
        return this.getDaysRange(tzGap, -6);
    }

    public last30days(tzGap): DateRange {
        return this.getDaysRange(tzGap, -29);
    }

    public lastMonth(tzGap): DateRange {
        return this.getMonthRange(tzGap, -1);
    }

    public thisMonth(tzGap): DateRange {
        return this.getMonthRange(tzGap, 0, 1);
    }

    private now(tzGap: number): DateRange {
        return [new Date(Date.now() - tzGap*1000*3600), new Date(Date.now() - tzGap*1000*3600)];
    }

    private getHoursRange(tzGap: number, startDiff = 0, endDiff = 0): DateRange {
        const [start, end] = this.now(tzGap);
        start.setHours(start.getHours() + startDiff);
        start.setMinutes(0, 0, 0);
        end.setHours(end.getHours() + endDiff);
        end.setMinutes(59, 59, 59);
        return [start, end];
    }

    private getTodayRange(tzGap: number): DateRange {
        const [start, end] = this.now(tzGap);
        start.setHours(0);
        start.setMinutes(0, 0, 0);
        end.setHours(23);
        end.setMinutes(59, 59, 59);
        return [start, end];
    }

    private getDaysRange(tzGap: number, startDiff = 0, endDiff = 0): DateRange {
        const [start, end] = this.getTodayRange(tzGap);
        start.setDate(start.getDate() + startDiff);
        end.setDate(end.getDate() + endDiff);
        return [start, end];
    }

    private getMonthRange(tzGap: number, startMonthDiff = 0, endMonthDiff = 0): DateRange {
        const date = new Date((Date.now() - tzGap*1000*3600));
        const start = new Date(date.getFullYear(), date.getMonth() + startMonthDiff, 1);
        const end = new Date(date.getFullYear(), date.getMonth() + endMonthDiff, 0);
        start.setHours(0);
        start.setMinutes(0, 0, 0);
        end.setHours(23);
        end.setMinutes(59, 59, 59);
        return [start, end];
    }

    public pickTZGap(value: number | null): number {
        let tz = value ? (value || 0) : 0;
        let localOffset = new Date().getTimezoneOffset() / 60;
        let tzGap = (-localOffset) - tz;
        return tzGap;
    }

    public pickFromType(type: string): DateRange {
        const tzGap = this.pickTZGap(0);
        switch(type) {
            case 'this_hour':
                return this.thisHour(tzGap);
            case 'last_hour':
                return this.lastHour(tzGap);
            case 'today':
                return this.today(tzGap);
            case 'yesterday':
                return this.yesterday(tzGap);
            case 'last_7_days':
                return this.last7days(tzGap);
            case 'last_30_days':
                return this.last30days(tzGap);
            case 'last_month':
                return this.lastMonth(tzGap);
            case 'this_month':
                return this.thisMonth(tzGap);
            default:
                throw new Error(`undefined type of DateRange ${type}`)
        }
    }
}

type DateRange = [Date, Date];