<template lang="pug">
.wrapper-date-time-range-picker 
    time-picker(v-model:hours="startDateHours" v-model:minutes="startDateMinutes" block_minutes="block_minutes")
    time-picker(v-model:hours="endDateHours" v-model:minutes="endDateMinutes" block_minutes="block_minutes")
</template>

<script>
import { defineComponent } from 'vue';
import {useTimeRange} from "@/hooks/useTimeRange"
import TimePicker from "./TimePicker.vue";

export default defineComponent({
    name: "TimeRangePicker",
    components: {TimePicker},
    props: {
        modelValue: {
            default: {
                type: Array, 
                default: [new Date(), new Date()]
            },
            required: true
        },
        block_minutes: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    setup(props) {
        const { startDateHours, startDateMinutes, endDateHours, endDateMinutes, resultRange } = useTimeRange({dateRange: props.modelValue});
        return {
            startDateHours,
            startDateMinutes,
            endDateHours,
            endDateMinutes,
            resultRange,
        }
    },
    watch: {
        resultRange() {
            this.$emit('update:modelValue', this.resultRange);
        }
    }
})
</script>
<style lang="scss" scoped>
    .wrapper-date-time-range-picker {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin: auto; 
    }
    
</style>
