
import { defineComponent } from 'vue';
import {TIMEZONE_OPTIONS} from './constants';

export default defineComponent({
    data() {
        return {
            options: TIMEZONE_OPTIONS
        }
    },
    props: {
        timezone: {
            type: Number,
            default: 0,
            required: true
        }
    },
    methods: {
        update(v: string | number) {
            this.$emit('update:timezone', +v);
        }
    }
})
